<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <h3 class="text-dark mb-4 multi-language">{{ 'Employee Debts' }}</h3>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-lg-1 col-md-1  mt-2">
                            Staffs:
                        </div>
                        <div class="col-12 col-lg-3 col-md-3">
                            <select class="form-control" v-model="st_id">
                                <option value=""></option>
                                <option v-for="staff in staffs" :key="staff.st_id" :value="staff.st_id">
                                    {{ staff.staff_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-lg-3 col-md-3 mb-2" v-for="employee in employees" :key="employee.emp_id">
                            <button class="btn btn-primary btn-block" @click="getEmployeeDebtInfo(employee.emp_id)"> {{ employee.full_name }} </button>
                        </div>
                    </div>
                    <hr>
                    <div class="my-1 text-dark"> Employee: {{ full_name }}
                        &nbsp;
                        <button class="btn btn-success btn-sm mx-3" v-if="full_name" data-toggle="modal" data-target="#mdl_transaction" @click="clearTransaction();"> <i class="fa fa-plus"></i> </button>
                        <button class="btn btn-info btn-sm mx-3" v-if="full_name" @click="clearEmployee();"> <i class="fa fa-broom"></i> </button>
                    </div>
                    <div class="my-1 alert alert-warning" v-if="debt < 0"> The Employee must retrive: {{ (debt * -1).toLocaleString() }} {{ salary_type == 'Daily' ? 'IQD': '$' }} </div>
                    <div class="my-1 alert alert-primary" v-else> The Company must give: {{ (debt).toLocaleString() }} {{ salary_type == 'Daily' ? 'IQD': '$' }} </div>
                    <v-data-table :headers="transactionHeader" :items="debts" :foote-props="{'items-per-page-options': [15, 20]}" class="text-center">
                        <template v-slot:item="{item, index}">
                            <tr>
                                <td> {{ index + 1 }} </td>
                                <td> {{ (item.transactionAmount > 0 ? item.transactionAmount : item.transactionAmount * -1).toLocaleString() }} {{ salary_type == "Daily" ? "IQD" : "$" }} </td>
                                <td> {{ item.transactionType | typeFilter }} </td>
                                <td> {{ new Date(item.transactionDate).toISOString().split('T')[0] }} </td>
                                <td>
                                    <v-btn color="primary" icon data-toggle="modal" data-target="#mdl_transaction" @click="getSingleTransaction(item)"> <v-icon> mdi-pencil </v-icon> </v-btn>
                                    <v-btn color="error" icon @click="deleteTransaction(item.dtID)"> <v-icon> mdi-delete </v-icon> </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <change-pass/>

        <div class="modal" id="mdl_transaction">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="mt-2"> Transaction Info </h5>
                        <button class="float-right btn btn-danger" data-dismiss="modal" @click="clearTransaction()"> &times; </button>
                    </div>
                    <div class="modal-content">
                        <div class="container-fluid">
                            <div class="row px-2 py-3">
                                <div class="col-1 pt-2">
                                    Amount:
                                </div>
                                <div class="col-12 col-md-11 col-lg-11">
                                    <input type="number" class="form-control" v-model="singleTransaction.amount">
                                </div>
                                <div class="form-check col-12 my-3">
                                    <input class="form-check-input" type="radio" name="radio" value="Minus" v-model="singleTransaction.transactionType">
                                    <label class="form-check-label">
                                        Give to Employee
                                    </label>
                                </div>
                                <div class="form-check col-12 my-1">
                                    <input class="form-check-input" type="radio" name="radio" value="Plus" v-model="singleTransaction.transactionType">
                                    <label class="form-check-label">
                                        Retrive from Employee
                                    </label>
                                </div>
                                <div class="col-1 pt-2 mt-3">
                                    Note:
                                </div>
                                <div class="col-12 col-md-11 col-lg-11 mt-3">
                                    <textarea type="number" class="form-control" v-model="singleTransaction.note"> </textarea>
                                </div>
                                <button class="btn btn-primary btn-block mt-1" @click="addTransaction()" v-if="!singleTransaction.dtID"> Add </button>
                                <button class="btn btn-warning btn-block mt-1" v-else @click="updateTransaction()"> Save changes </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

    </div>
</template>

<script>
import auth from "../auth"
import showError from "../showError"
export default {
    data() {
        return {
            staffs: [],
            employees: [],
            emp_id: [],
            st_id: null,
            full_name: null,
            salary_type: 'Daily',
            debts: [],
            singleTransaction: {
                dtID: null,
                amount: 0,
                transactionType: null,
                note: null
            }
        }
    },
    beforeCreate() {
        auth("attendance");
    },
    created() {
        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        }).catch((err)=>{
            showError(err);
        });
    },
    methods: {
        clearTransaction() {
            this.singleTransaction.dtID = null;
            this.singleTransaction.amount = 0;
            this.singleTransaction.transactionType = null;
            this.singleTransaction.note = null;
        },
        clearEmployee() {
            this.emp_id = null;
            this.full_name = null;
            this.salary_type = 'Daily';
            this.debts = [];
        },
        getEmployeeDebtInfo(emp_id) {
            const employee = this.employees.find(obj => obj.emp_id == emp_id);
            this.emp_id = emp_id;
            this.full_name = employee.full_name;
            this.salary_type = employee.salary_type;
            this.$http.get('/employee/getEmployeeTransaction/' + emp_id).then(({data}) => {
                this.debts = data;
            });
        },
        addTransaction() {
            if(this.singleTransaction.amount > 0 && this.singleTransaction.transactionType) {
                this.$http.post('/employee/addDebtTransaction', {
                    emp_id: this.emp_id,
                    transactionAmount: this.singleTransaction.amount,
                    transactionType: this.singleTransaction.transactionType,
                    note: this.singleTransaction.note
                }).then(({data}) => {
                    this.debts.unshift({
                        dtID: data.dtID,
                        emp_id: this.emp_id,
                        transactionAmount: this.singleTransaction.transactionType == 'Minus' ? this.singleTransaction.amount * -1 : this.singleTransaction.amount,
                        transactionType: this.singleTransaction.transactionType,
                        transactionDate: new Date().toISOString().split('T')[0],
                        note: this.singleTransaction.note || null
                    });
                    this.clearTransaction();
                });
            } else {
                this.$alert('Please fill all informations correctly', '', 'warning');
            }
        },
        getSingleTransaction(transaction) {
            this.singleTransaction.dtID = transaction.dtID;
            this.singleTransaction.amount = transaction.transactionType == 'Plus' ? transaction.transactionAmount : transaction.transactionAmount * -1;
            this.singleTransaction.transactionType = transaction.transactionType;
            this.singleTransaction.note = transaction.note;
        },
        updateTransaction() {
            this.$http.patch('/employee/updateDebtTransaction/' + this.singleTransaction.dtID, {
                transactionAmount: this.singleTransaction.amount,
                transactionType: this.singleTransaction.transactionType,
                note: this.singleTransaction.note
            }).then(() => {
                let index = this.debts.findIndex(obj => obj.dtID == this.singleTransaction.dtID);
                this.debts[index].transactionAmount = this.singleTransaction.transactionType == 'Minus' ? this.singleTransaction.amount * -1 : this.singleTransaction.amount;
                this.debts[index].transactionType = this.singleTransaction.transactionType;
                this.debts[index].note = this.singleTransaction.note;
                this.$alert('Success', '', 'success');
            });
        },
        deleteTransaction(dtID) {
            this.$confirm('Are you sure delete this data ?', '', 'question').then(() => {
                this.$http.delete('/employee/deleteDebtTransaction/' + dtID).then(() => {
                    this.debts = this.debts.filter(obj => obj.dtID != dtID);
                });
            });
        }
    },
    computed: {
        debt() {
            return this.debts.reduce((acc, n) => acc + Number(n.transactionAmount), 0);
        },
        transactionHeader() {
            return [
                { text: '#', align: 'center', sortable: false },
                { text: 'Amount', align: 'center', sortable: false },
                { text: 'Type', align: 'center', sortable: false },
                { text: 'Date', align: 'center', sortable: false },
                { text: 'Actions', align: 'center', sortable: false },
            ]
        }
    },
    filters: {
        typeFilter(value) {
            return {
                Plus: 'Retrieved from Employee',
                Minus: 'Gived to Employee',
            }[value]
        }
    },
    watch: {
        st_id(value) {
            if(value) {
                this.$http.post('/employee/getEmployeeBystaff/' + value).then(({data}) => {
                    this.employees = data;
                });
            } else {
                this.employees = [];
            }
        }
    }
}
</script>


